import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { trackPromise } from "react-promise-tracker";
import { DataGrid, GridColDef, GridToolbar, GridFilterModel, GridSortModel, GridFilterItem } from "@mui/x-data-grid";
import axios from "axios";

import { RootState } from "&store/store";

import PageHeader from "&styled/page-header";

import { csvJobActions } from "./csvJob.slice";

const statusMapping = {
  pending: {
    title: "Pending",
    bgColor: "rgb(255, 239, 189)",
    color: "rgb(155, 116, 0)",
  },
  inprogress: {
    title: "InProgress",
    bgColor: "rgb(214, 255, 217)",
    color: "rgb(15, 160, 26)",
  },
  completed: {
    title: "Completed",
    bgColor: "rgb(214, 255, 217)",
    color: "rgb(15, 160, 26)",
  },
  failed: {
    title: "Failed",
    bgColor: "rgb(255, 228, 228)",
    color: "rgb(255, 29, 29)",
  },
  cancelled: {
    title: "Cancelled",
    bgColor: "rgb(255, 228, 228)",
    color: "rgb(255, 29, 29)",
  },
  deleted: {
    title: "Deleted",
    bgColor: "rgb(255, 228, 228)",
    color: "rgb(255, 29, 29)",
  },
};

function calculatePercentage(completedRecords: number, totalRecords: number) {
  if (totalRecords === 0) {
    return 0; // To avoid division by zero
  }
  return (completedRecords / totalRecords) * 100;
}

type ReduxProps = ConnectedProps<typeof connector>;

const CsvJobComponent = (props: ReduxProps) => {
  const { state, getCSVJobs, token } = props;
  const { results, count } = state;
  const [filters, setFilters] = useState<{
    sorting: GridSortModel;
    filtering: GridFilterItem[];
    page: number;
    limit: number;
  }>({
    sorting: [],
    filtering: [],
    page: 0,
    limit: 10,
  });

  const columns: GridColDef[] = [
    {
      field: "query",
      headerName: "Date Query",
      width: 250,
      headerAlign: "left",
      align: "left",
      valueFormatter: ({ value }) => {
        const json = JSON.parse(value);
        let startDate = moment().format("DD MMM YYYY");
        let endDate = moment().format("DD MMM YYYY");
        if (json?.transactionDate?.["$gte"]) {
          startDate = moment(json.transactionDate["$gte"]).format("DD MMM YYYY");
        }
        if (json?.transactionDate?.["$lte"]) {
          endDate = moment(json.transactionDate["$lte"]).format("DD MMM YYYY");
        }
        return `${startDate} - ${endDate}`;
      },
    },
    {
      field: "module",
      headerName: "Module",
      width: 250,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Created Date/Time",
      width: 300,
      valueFormatter: ({ value }) => {
        return moment(value).format("DD MMM YYYY HH:mm:ss A");
      },
      renderCell: ({ row }) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss A"),
      headerAlign: "left",
      align: "left",
      type: "date",
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ row }) => (
        <Box
          sx={{
            padding: "0.2rem 1rem",
            borderRadius: "100px",
            backgroundColor: statusMapping[row.status].bgColor,
            color: statusMapping[row.status].color,
          }}
        >
          {row.status}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "records",
      headerName: "Progress",
      width: 150,
      headerAlign: "left",
      align: "left",
      renderCell: ({ row }) =>
        `${calculatePercentage(row?.records?.completedRecords ?? 0, row?.records?.totalRecords ?? 0).toFixed(2)} %`,
      filterable: false,
      sortable: false,
    },
    {
      field: "_id",
      headerName: "Link",
      width: 300,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ row }) => (
        <Box
          sx={{
            padding: "0.2rem 1rem",
            borderRadius: "100px",
            backgroundColor: row.status === "completed" ? "green" : "",
            color: "white",
            cursor: row.status === "completed" ? "pointer" : "none",
          }}
          onClick={() => downloadCSVFile(row._id)}
        >
          {row.status === "completed" ? "Download" : ""}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
  ];

  const onPageChange = async (page) => {
    setFilters((prev) => ({
      ...prev,
      page,
    }));
  };

  const onFilterChange = (obj: GridFilterModel) => {
    setFilters((prev) => ({
      ...prev,
      filtering: obj.items,
      page: 0,
    }));
  };
  const onSortChange = async (obj: GridSortModel) => {
    setFilters((prev) => ({
      ...prev,
      sorting: obj,
      page: 0,
    }));
  };

  async function downloadCSVFile(_id: string) {
    try {
      const response = await trackPromise(
        axios.get(`${process.env.REACT_APP_CSV_JOB_URL}/csv-jobs/${_id}/download/`, {
          headers: {
            "x-auth-token": token,
          },
        })
      );
      if (response?.data?.data?.url) {
        const aTag = document.createElement("a");
        aTag.href = response.data.data.url;
        aTag.target = "_blank";
        aTag.download = `CSV_${_id}.csv`;
        aTag.click();
      }
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    getCSVJobs({
      page: filters.page,
      limit: filters.limit,
      sorting: filters.sorting,
      filtering: filters.filtering,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.page, filters?.sorting, filters?.filtering]);

  return (
    <>
      <PageHeader title="CSV Jobs" addText="" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: "#F6F2FF",
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                onSortModelChange={onSortChange}
                onFilterModelChange={onFilterChange}
                onPageChange={onPageChange}
                rows={results}
                columns={columns}
                pageSize={filters.limit}
                rowsPerPageOptions={[]}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                rowCount={count}
                page={filters.page}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.csvJobs,
  token: state.login.token,
});

const mapDispatchToProps = {
  getCSVJobs: csvJobActions.getCSVJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CsvJobComponentRedux = connector(CsvJobComponent);

export { CsvJobComponentRedux as CsvJobComponent };
