import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import { DataGrid, GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import { truncate } from "lodash";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { Button } from "@mui/material";

import { infoAlert, successAlert } from "&config/swalGenerator";

export default function TransactionReport({ onSearch, data = [], user, updateStatus, count, token }) {
  const [filters, setFilters] = useState<any>({
    dateFrom: new Date(),
    dateTo: new Date(),
    country: "Pakistan",
    currency: "PKR",
    page: 1,
  });
  const changeTransactionStatus = async (status, id) => {
    if (user.userTypeId !== "Walee") return;
    let updatedStatus = status === "Failed" ? "Success" : "Failed";
    const payload = { status: updatedStatus, id };
    await updateStatus(payload);
  };

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "ID",
      width: 150,
      renderCell: ({ row }) => <Box sx={{ color: "#6631F7" }}>{row.transactionId}</Box>,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "Account",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "productName",
      headerName: "Product",
      width: 150,
      renderCell: ({ row }) => truncate(row.productName, { length: 15, separator: "." }),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date/Time",
      width: 200,
      valueFormatter: ({ value }) => {
        return formatDate(value);
      },
      renderCell: ({ row }) => moment(row.transactionDate).format("DD/MM/YYYY HH:mm:ss A"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "amount",
      headerName: "Transaction Amount",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.amount?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },

    {
      field: "merchantShareValue",
      headerName: "Merchant Share (96%)",
      width: 200,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.merchantShareValue?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "waleeShareValue",
      headerName: "Walee Share",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.waleeShareValue?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "netAmount",
      headerName: "Net Amount",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.netAmount?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "",
      width: 150,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ row }) => (
        <>
          <Box
            sx={{
              padding: "0.2rem 1rem",
              borderRadius: "100px",
              backgroundColor: row.status === "Success" ? "#7BAD38" : "red",
              color: "#fff",
              cursor: user?.userTypeId === "Walee" ? "pointer" : "null",
            }}
            onClick={() => changeTransactionStatus(row.status, row._id)}
          >
            {row.status}
          </Box>
        </>
      ),
      headerAlign: "left",
      align: "left",
    },
  ];

  const onPageChange = async (page) => {
    setFilters({ ...filters, page: page + 1 });
  };
  const onSortChange = async (obj) => {
    if (obj?.[0]?.field) {
      const { field, sort } = obj[0];
      setFilters({ ...filters, sortObj: { field, sortBy: sort }, page: 1 });
    }
  };

  const onFilterChange = async (obj) => {
    if (!obj?.items?.[0]?.value) {
      setFilters(
        Object.keys(filters).reduce((prev, current) => {
          if (["dateFrom", "dateTo", "country", "currency", "page"].includes(current)) {
            prev[current] = filters[current];
          }
          return prev;
        }, {})
      );
    } else {
      const searchObj = {};
      if (obj.items[0].columnField !== "transactionDate") {
        if (obj.items[0].operatorValue === "equals") {
          searchObj[obj.items[0].columnField] = obj.items[0].value;
        } else if (obj.items[0].operatorValue === "contains") {
          searchObj[obj.items[0].columnField] = {
            $regex: obj.items[0].value,
            $options: "i",
          };
        }
      }
      setFilters((prev) => {
        return { ...prev, searchObj, page: 1 };
      });
    }
  };
  const formatDate = (val) => {
    return moment(val).format("DD MMM YYYY HH:mm:ss A");
  };

  async function exportData() {
    try {
      const response = await trackPromise(
        axios.post(`${process.env.REACT_APP_CSV_JOB_URL}/csv-jobs/transactions/`, filters, {
          headers: {
            "x-auth-token": token,
          },
        })
      );
      if (response.data.is_success) {
        successAlert("Success", "CSV file is being generated. You will be notified once it is ready.");
      } else {
        infoAlert("Failed", "Failed to generate CSV file");
      }
    } catch (error) {
      console.log("error", error);
      infoAlert("Failed", "Failed to generate CSV file");
    }
  }

  useEffect(() => {
    onSearch(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <PageHeader
        title="Transaction Report"
        onSearch={(data) => {
          setFilters((prev) => ({
            ...prev,
            page: 1,
            dateFrom: data?.dateFrom ?? prev.dateFrom,
            dateTo: data?.dateTo ?? prev.dateTo,
            country: data?.country,
            currency: data?.currency,
          }));
        }}
        type="transaction"
        defaultCurrency="PKR"
      />
      <Button onClick={() => exportData()}>Export</Button>
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: "#F6F2FF",
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                onSortModelChange={onSortChange}
                onFilterModelChange={onFilterChange}
                onPageChange={onPageChange}
                rows={data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[]}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                rowCount={count}
                page={filters.page - 1}
                getCellClassName={(params: GridCellParams<number>) => {
                  if (
                    params.field === "carrierShareValue" ||
                    params.field === "merchantShareValue" ||
                    params.field === "waleeShareValue"
                  ) {
                    return "purple";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
