import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { DataGrid, GridColDef, GridFilterItem, GridSortModel, GridToolbar } from "@mui/x-data-grid";
import { Alert, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CardContent from "@mui/material/CardContent";
import ExportButton from "./exportButton";
import PageHeader from "&styled/page-header/pageHeader";
import { RootState } from "&store/store";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { loanRequestsActions } from "./loanRequests.slice";
import { getCurrentUser } from "&config/getCurrentUser";
import SearchIcon from "&assets/icons/search";
import AvatarIcon from "&assets/images/avatar.png";
import CheckIcon from "&assets/icons/check";
import CrossIcon from "&assets/icons/x";
import UploadIcon from "&assets/icons/upload";
import ConfirmationModal from "./confirmationModal";
import UploadModal from "./uploadModal";
import { CheckMimeType } from "&styled/file-upload/check-mime-type";
import { OutlinedButton, SubmitButton } from "&styled/button/button.component";
import DatePickerComponent from "&styled/form/date-picker";
import RefreshIcon from "@mui/icons-material/Refresh";
import { successAlert, infoAlert, errorAlert } from "&config/swalGenerator";
import ExportIcon from "&assets/icons/export";
import momentTimezone from "moment-timezone";

type ReduxProps = ConnectedProps<typeof connector>;

interface State {
  query: string;
  status: string;
  from: string;
  to: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "8px",
  p: 1,
};

const LoanRequestsComponent = (props: ReduxProps) => {
  const {
    state,
    getLoanRequests,
    searchLoanRequests,
    token,
    changeStatus,
    fileUpload,
    fileParsed,
    uploadProgress,
    deleteFile,
    resetUploadProgress,
    processAutoJobs,
    processEasypaisaAutoJobs,
    getJobStatus,
    refreshLoanRequest,
    refreshEasypaisaLoanRequest,
    getEasypaisaJobStatus,
    getLoanRequestsExport,
  } = props;

  const { data, count, autoJobStatus, epAutoJobStatus } = state;
  const user: any = getCurrentUser(token);
  const { userTypeId } = user;
  const [search, setSearch] = useState<{
    sorting: GridSortModel;
    filtering: GridFilterItem[];
    sortObj: { field: string; sortBy: "asc" | "desc" };
    page: number;
    limit: number;
    query: string;
    from: Date;
    to: Date;
    status: string;
    run: boolean;
  }>({
    query: "",
    status: "All",
    from: new Date(),
    to: new Date(),
    sorting: [],
    filtering: [],
    page: 0,
    limit: 10,
    run: true,
    sortObj: {
      field: "createdDate",
      sortBy: "asc",
    } as any,
  });
  const [open, setOpen] = React.useState(false);
  const [loanRec, setLoanRec] = useState<any>({});
  const [fileUploadBtnDisabled, setFileUploadBtnDisabled] = useState(false);
  const [fileUploadSummary, setFileUploadSummary] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [file, setFile] = useState<any>("");
  const [recId, setRecId] = useState("");
  const [recMerchant, setRecMerchant] = useState("");
  const [recAmount, setRecAmount] = useState("");
  const [recStatus, setRecStatus] = useState("");
  const [uploadView, setUploadView] = useState("fileUpload");
  const [error, setError] = useState("");
  const [exportData, setExportData] = useState([]);
  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  const handleOpenConfirmationModal = (row, status) => {
    setRecId(row._id);
    setRecAmount(row.amount);
    setRecMerchant(row.userTypeId);
    setRecStatus(status);
    setConfirmationModalOpen(true);
  };
  const handleCloseConfirmationModal = () => {
    setRecId("");
    setRecAmount("");
    setRecMerchant("");
    setRecStatus("");
    setConfirmationModalOpen(false);
  };

  const handleFileUploadModalClose = () => {
    setError("");
    setFileUploadSummary({});
    setUploadView("fileUpload");
    setFile("");
    resetUploadProgress(0);
    setFileUploadBtnDisabled(false);
    setFileUploadModalOpen(false);
  };
  const handleClose = () => setOpen(false);

  const handleChangeStatus = async () => {
    if (["Approved", "Rejected"].includes(recStatus)) {
      const params = {
        id: recId,
        status: recStatus,
        amount: recAmount,
        merchant: recMerchant,
      };
      await changeStatus(params);
    }
    if (recStatus === "Refresh") {
      const data = await refreshLoanRequest({ _id: recId });
      if (data?.payload?.status === "success") {
        successAlert("Info", data?.payload.message);
      } else if (data?.payload?.status === "failed") {
        infoAlert("Error", data?.payload.message);
      } else {
        errorAlert();
      }
    }
    if (recStatus === "Refresh-EastPaisa") {
      const data = await refreshEasypaisaLoanRequest({ _id: recId });
      if (data?.payload?.status === "success") {
        successAlert("Info", data?.payload.message);
      } else if (data?.payload?.status === "error") {
        infoAlert("Error", data?.payload.message);
      } else {
        errorAlert();
      }
    }
    setConfirmationModalOpen(false);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (!CheckMimeType(file)) {
      return toast.error("Only xlsx & xls files supported", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setFile(file);
  };
  const handleFileUpload = async () => {
    if (!file) {
      return toast.error("Please select file", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setFileUploadBtnDisabled(true);
    try {
      const { payload } = await fileUpload(file);
      if (payload.error_code) {
        setUploadView("error");
        return setError(payload.err_desc);
      }
      setFileUploadSummary(payload.data);
      setUploadView("fileInfo");
    } catch (e) {
      console.log(e);
    }

    setFileUploadBtnDisabled(false);
  };

  const columns: GridColDef[] = [
    {
      field: "loanId",
      headerName: "Payout ID",
      renderCell: ({ row }) => (
        <Box sx={{ color: "#6631F7", cursor: "pointer" }} onClick={() => handleModalOpen(row._id)}>
          {row.loanId}
        </Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "merchantName",
      headerName: "Merchant",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "bankName",
      headerName: "Operator",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "certName",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
      renderCell: ({ row }) => renderContact(row),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdDate",
      headerName: "Request Date",
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY HH:mm A"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "amount",
      headerName: "Amount",
      renderCell: ({ row }) => pkFormatter.format(row.amount),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "STATUS",
      renderCell: ({ row }) => renderStatus(row.status),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    getJobStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const filters = {
      ...search,
    };
    delete (filters as any).page;
    delete (filters as any).limit;
    getLoanRequests(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.run]);

  // handler
  const handleModalOpen = (id) => {
    const loan = data.find((l) => l._id === id);
    setLoanRec(loan);
    setOpen(true);
  };
  const onPageChange = async (page) => {
    setSearch((prev) => ({
      ...prev,
      page,
      run: !prev.run,
    }));
  };

  const onSortChange = async (obj) => {
    if (obj?.[0]?.field) {
      const { field, sort } = obj[0];
      setSearch({ ...search, sortObj: { field, sortBy: sort }, page: 0, run: !search.run });
    }
  };
  // renderer
  const renderContact = (row) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            color: "#111111",
            font: "normal normal normal 12px/18px Poppins",
          }}
        >
          {row.beneficiaryEmail}
        </Box>
        <Box
          sx={{
            color: "#838383",
            font: "normal normal normal 9px/13px Poppins",
          }}
        >
          {row.msisdn}
        </Box>
      </Box>
    );
  };
  const renderStatus = (status) => {
    return (
      <Box
        sx={{
          backgroundColor: status === "Pending" ? "#ffefbd" : status === "Approved" ? "#D6FFD9" : "#FFE4E4",
          color: status === "Pending" ? "#9b7400" : status === "Approved" ? "#0FA01A" : "#FF1D1D",
          width: "62px",
          textAlign: "center",
          borderRadius: "16px",
          padding: "5px",
          font: "normal normal normal 10px/16px Poppins",
        }}
      >
        {status}
      </Box>
    );
  };

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSearch((prevState) => ({
      ...prevState,
      status: event.target.value as string,
    }));
  };

  const handleDateChange = (val, type) => {
    setSearch((prevState) => ({ ...prevState, [type]: val }));
  };

  const renderReject = (row) => {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          background: "#FFECEC 0% 0% no-repeat padding-box",
          border: "2px solid #E53030",
          borderRadius: "25px",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => handleOpenConfirmationModal(row, "Rejected")}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CrossIcon />
        </Box>
      </Box>
    );
  };
  const renderApprove = (row) => {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          background: "#E6FFEF 0% 0% no-repeat padding-box",
          border: "2px solid #0DB94C",
          borderRadius: "25px",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => handleOpenConfirmationModal(row, "Approved")}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CheckIcon />
        </Box>
      </Box>
    );
  };

  const renderRequestAgain = (row) => {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          background: "#E6FFEF 0% 0% no-repeat padding-box",
          border: "2px solid #0DB94C",
          borderRadius: "25px",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => handleOpenConfirmationModal(row, "Refresh")}
      >
        <Box
          sx={{
            position: "absolute",
            top: "57%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#0DB94C",
          }}
        >
          <RefreshIcon />
        </Box>
      </Box>
    );
  };

  const renderRequestAgainEp = (row) => {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          background: "#E6FFEF 0% 0% no-repeat padding-box",
          border: "2px solid #0DB94C",
          borderRadius: "25px",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => handleOpenConfirmationModal(row, "Refresh-EastPaisa")}
      >
        <Box
          sx={{
            position: "absolute",
            top: "57%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#0DB94C",
          }}
        >
          <RefreshIcon />
        </Box>
      </Box>
    );
  };

  const renderButtons = (row) => {
    return (
      <>
        {row.status === "Pending" && (
          <Box>
            {userTypeId === "Walee" && (
              <Box sx={{ display: "flex", gap: 1 }}>
                {row?.bankName?.toLowerCase()?.startsWith("eas") ? renderRequestAgainEp(row) : null}
                {row?.bankName?.toLowerCase().startsWith("jazz") ? renderRequestAgain(row) : null}
                {renderReject(row)}
                {renderApprove(row)}
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const handleFileRec = () => {
    window.location.href = "/file-records";
  };

  const renderGhostBtn = (title) => {
    if (title === "fileRecord") {
      return (
        <Box
          sx={{
            border: "1px solid #6631F7",
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            color: "#6631F7",
            cursor: "pointer",
          }}
          onClick={handleFileRec}
        >
          File Record
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            border: "1px solid #6631F7",
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            color: "#6631F7",
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => setFileUploadModalOpen(true)}
        >
          <UploadIcon />
          Payout Approval
        </Box>
      );
    }
  };
  // Jazzcash Auto Job
  const handleAutoJobs = async () => {
    try {
      await processAutoJobs({ status: autoJobStatus });
    } catch (e) {
      console.log(e);
    }
  };
  // Easypaisa Auto Job
  const handleEasypaisaAutoJobs = async () => {
    try {
      await processEasypaisaAutoJobs({ status: epAutoJobStatus });
    } catch (e) {
      console.log(e);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // Jazzcash auto job CTA
  const renderPayoutJobsBtn = () => {
    return (
      <Box
        sx={{
          border: "1px solid #6631F7",
          borderRadius: "5px",
          padding: "0.5rem 1rem",
          color: "#6631F7",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleAutoJobs}
      >
        {autoJobStatus === "start" && <PlayCircleOutlineIcon sx={{ marginRight: 1 }} />}
        {autoJobStatus === "stop" && <StopCircleIcon sx={{ marginRight: 1 }} />}
        {capitalizeFirstLetter(autoJobStatus)} Jazzcash Auto Payments
      </Box>
    );
  };

  // Easypaisa autojob CTA
  const renderEasypaisaPayoutJobsBtn = () => {
    return (
      <Box
        sx={{
          border: "1px solid #6631F7",
          borderRadius: "5px",
          padding: "0.5rem 1rem",
          color: "#6631F7",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleEasypaisaAutoJobs}
      >
        {epAutoJobStatus === "start" && <PlayCircleOutlineIcon sx={{ marginRight: 1 }} />}
        {epAutoJobStatus === "stop" && <StopCircleIcon sx={{ marginRight: 1 }} />}
        {capitalizeFirstLetter(epAutoJobStatus)} Easypaisa Auto Payments
      </Box>
    );
  };

  const renderExportBtn = () => {
    return (
      <>
        <ExportButton data={data} user={user} name="Loan Requests"></ExportButton>;
        {/* <Button
          onClick={() => {
            const filters = {
              ...search,
              timeZone: momentTimezone.tz.guess(),
            };
            delete (filters as any).page;
            delete (filters as any).limit;
            getLoanRequestsExport(filters);
          }}
          sx={{
            border: "1px solid #6631F7",
            backgroundColor: "#6631F7",
            display: "flex",
            gap: 1,
            alignItems: "center",
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#6631F7",
              color: "#ffffff",
            },
          }}
        >
          <ExportIcon />
          Export
        </Button> */}
      </>
    );
  };
  const renderCta = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: "80%",
            justifyContent: "flex-end",
          }}
        >
          {userTypeId === "Walee" && (
            <>
              {/*{renderBtnAccountBalance()}*/}
              {renderEasypaisaPayoutJobsBtn()}
              {renderPayoutJobsBtn()}
              {renderGhostBtn("fileRecord")}
              {renderGhostBtn("loanApproval")}
            </>
          )}
          {renderExportBtn()}
        </Box>
      </>
    );
  };

  const onSearch = async () => {
    try {
      setSearch({ ...search, run: !search.run, page: 0 });
    } catch (e) {
      console.log(e);
    }
  };
  const onReset = () => {
    setSearch((prev) => ({
      query: "",
      status: "All",
      from: new Date(),
      to: new Date(),
      sorting: [],
      filtering: [],
      page: 0,
      limit: 10,
      run: !prev?.run,
      sortObj: {} as any,
    }));
  };
  return (
    <>
      <PageHeader title="Payout Request" renderCta={renderCta} />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            {/*Search filters*/}
            <Box sx={{ marginY: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    {/*<InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>*/}
                    <OutlinedInput
                      id="outlined-adornment-search"
                      value={search.query}
                      size="small"
                      onChange={handleChange("query")}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      placeholder="Search"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2} marginTop={1}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={search.status}
                      onChange={handleSelectChange}
                      size="small"
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={2} marginTop={1}>
                  <DatePickerComponent
                    label="Date From"
                    value={search.from}
                    size="small"
                    onChange={(val) => handleDateChange(val, "from")}
                  />
                </Grid>
                <Grid item lg={2} marginTop={1}>
                  <DatePickerComponent
                    label="Date To"
                    value={search.to}
                    size="small"
                    onChange={(val) => handleDateChange(val, "to")}
                  />
                </Grid>
                <Grid item lg={1} marginTop={1}>
                  <SubmitButton title="Search" handlePress={onSearch} />
                </Grid>
                <Grid item lg={1} marginTop={1}>
                  <OutlinedButton title="Reset" handlePress={onReset} />
                </Grid>
                <Grid item lg={1} marginTop={1}>
                  <SubmitButton title="Summary" handlePress={onReset} />
                </Grid>
              </Grid>
            </Box>
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                /*initialState={{
                  sorting: {
                    sortModel: [{ field: "createdDate", sort: "asc" }],
                  },
                }}*/
                // paginationMode="server"
                // filterMode="server"
                // disableColumnFilter
                // sortingMode="server"
                // onSortModelChange={onSortChange}
                // onPageChange={onPageChange}
                rows={data}
                columns={columns}
                // pageSize={search.limit}
                pageSize={25}
                // rowsPerPageOptions={[]}
                rowsPerPageOptions={[25]}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                // rowCount={count}
                // page={search.page}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
      {/*Modal*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/*Header*/}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <Box
            sx={{
              font: " normal normal 600 18px/27px Poppins",
              marginLeft: "2rem",
            }}
          >
            {loanRec.loanId}
          </Box>
          <Box>
            <Alert
              severity={loanRec.status === "Pending" ? "info" : loanRec.status === "Rejected" ? "error" : "success"}
            >
              {loanRec.status === "Pending" ? "Pending" : loanRec.message}
            </Alert>
          </Box>
          <Grid container spacing={3} marginY={2} marginX={1}>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Name</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <img src={AvatarIcon} alt="avatar" style={{ width: "20px", height: "20px" }} />
                <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.certName}</Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Email</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.beneficiaryEmail}</Box>
            </Grid>
            {/*Phone Number*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Phone No</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.msisdn}</Box>
            </Grid>
            {/*Status*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Status</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{renderStatus(loanRec.status)}</Box>
            </Grid>
            {/*Status Date*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>
                {loanRec.status === "Pending" ? "Requested Date" : "Approval Date"}
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>
                {moment(loanRec.status === "Pending" ? loanRec.createdDate : loanRec.updatedDate).format(
                  "DD MMM YYYY HH:mmA"
                )}
              </Box>
            </Grid>
            {/*Bank Name*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Bank Name</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.bankName}</Box>
            </Grid>
            {/*Bank Name*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Bank Card No</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.bankNo}</Box>
            </Grid>
            {/*Payout Type*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Pay Out Type</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{loanRec.payoutType}</Box>
            </Grid>
            {/*Amount*/}
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 600 14px/21px Poppins" }}>Amount</Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ font: "normal normal 300 14px/21px Poppins" }}>{pkFormatter.format(loanRec.amount)}</Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmationModal
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleExit={handleChangeStatus}
        heading="Are you sure?"
        text="Changing the status will not revert back."
      />
      <UploadModal
        handleClose={handleFileUploadModalClose}
        open={fileUploadModalOpen}
        handleProceed={handleFileUpload}
        onChange={onFileChange}
        fileName={file.name}
        value={uploadProgress}
        fileUploadBtnDisabled={fileUploadBtnDisabled}
        fileUploadSummary={fileUploadSummary}
        uploadView={uploadView}
        deleteFile={deleteFile}
        fileParsed={fileParsed}
        error={error}
      />
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.loanRequests,
  token: state.login.token,
  uploadProgress: state.loanRequests.uploadProgress,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getLoanRequests: loanRequestsActions.getLoanRequests,
  searchLoanRequests: loanRequestsActions.searchLoanRequests,
  changeStatus: loanRequestsActions.changeStatus,
  refreshLoanRequest: loanRequestsActions.refreshLoanRequest,
  refreshEasypaisaLoanRequest: loanRequestsActions.refreshEasypaisaLoanRequest,
  fileUpload: loanRequestsActions.fileUpload,
  deleteFile: loanRequestsActions.deleteFile,
  fileParsed: loanRequestsActions.fileParsed,
  resetUploadProgress: loanRequestsActions.resetUploadProgress,
  processAutoJobs: loanRequestsActions.processAutoJobs,
  processEasypaisaAutoJobs: loanRequestsActions.processEasypaisaAutoJobs,
  getJobStatus: loanRequestsActions.getJobStatus,
  getEasypaisaJobStatus: loanRequestsActions.getEasypaisaJobStatus,
  getLoanRequestsExport: loanRequestsActions.getLoanRequestsExport,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const LoanRequestsComponentRedux = connector(LoanRequestsComponent);

export { LoanRequestsComponentRedux as LoanRequestsComponent };
