import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Backdrop, Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import SelectComponent from "&styled/form/select";
import { TextInput } from "&styled/textField/textField.component";
import { SubmitButton } from "&styled/button/button.component";
import axios from "axios";

function ResponseComponent({
  response,
  title,
}: Readonly<{ response: string | Record<string, string>; title: string }>) {
  if (typeof response === "string" && response !== "loading") {
    return (
      <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
        {response}
      </Box>
    );
  }
  if (typeof response == "object") {
    return (
      <>
        <Box textAlign={"center"} mb={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        {Object.entries(response ?? {}).map(([key, value]) => (
          <Box key={key}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {key}
              </Typography>
              <Typography variant="body2">{typeof value == "string" ? value : JSON.stringify(value)}</Typography>
            </Box>
          </Box>
        ))}
      </>
    );
  }
  return null;
}

export default function Inquire(props: any) {
  const [response, setResponse] = useState<string | Record<string, string> | null>(null);
  const [checkoutLogs, setCheckoutLogs] = useState<any>(null);
  const formik = useFormik({
    initialValues: {
      provider: "easypaisa",
      orderId: "",
    },
    validationSchema: Yup.object({
      provider: Yup.string().oneOf(["easypaisa", "jazzcash"]).required("Provider is required"),
      orderId: Yup.string().required("Order Id is required"),
    }),
    onSubmit: async (values) => {
      try {
        setResponse("loading");
        setCheckoutLogs(null);
        const response = await axios.post(`${process.env.REACT_APP_INQUIRE_URL}/payin-inquire/get`, values, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": props.token,
          },
        });
        if (response?.data?.status === "success") {
          setResponse(response.data.response);
          setCheckoutLogs(response.data.checkoutLogs);
        } else {
          setResponse(response?.data?.message ?? "Some issue on inquire");
          setCheckoutLogs(null);
        }
      } catch (error) {
        console.log(error);
        setResponse("Some issue on inquire");
        setCheckoutLogs(null);
      }
    },
  });

  return (
    <Grid container spacing={2}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={response === "loading"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} md={6}>
        <Paper elevation={1} sx={{ p: 3, height: 300, overflow: "auto" }}>
          <Box display="flex" flexDirection={"column"} gap={2}>
            <SelectComponent
              value={formik.values.provider}
              onSelect={(e) => {
                formik.setFieldValue("provider", e.target.value);
              }}
              menuItems={[
                {
                  value: "easypaisa",
                  label: "Easypaisa",
                },
                {
                  value: "jazzcash",
                  label: "Jazzcash",
                },
              ]}
              placeHolder="Select Provider"
              hasError={!!formik.errors.provider}
              errorMessage={formik.errors.provider as string}
            />
            <TextInput
              placeHolder={"Order Id"}
              required={false}
              value={formik.values.orderId}
              type="text"
              handleTextChange={(e) => formik.setFieldValue("orderId", e)}
              hasError={!!formik.errors.orderId}
              errorMessage={formik.errors.orderId}
            />
            <Box>
              <SubmitButton
                title="Inquire"
                handlePress={() => {
                  formik.handleSubmit();
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={1} sx={{ p: 3, height: 300, overflow: "auto" }}>
          {response ? (
            <ResponseComponent response={response} title={`${formik.values.provider} Logs`} />
          ) : (
            <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
              Easypaisa Inquire Details will be shown here
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}></Grid>
      {checkoutLogs ? (
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 3, height: 300, overflow: "auto" }}>
            <ResponseComponent response={checkoutLogs?.response} title="System Logs" />
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
}
